<template>
    <div :class="mobileStatus ? 'dash-header' : 'dash-header-dark'">
      <span class="dash-header-span-title">{{dataTime}}</span>
<!--      <span class="dash-header-span-datatime">{{corporate_name}}</span>-->
      <router-link  key="expand" class="navbar-logo-link" to="/business/index">
      <h1>智能库房云监测预警平台</h1>
      </router-link>
      <span  class="dash-header-span-datatime"><i class="el-icon-s-operation" :style="iot_error_flag?'color: #00ffbe;margin-right: 0.3vw':'color: #ff5076;margin-right: 0.3vw'"></i>IOT服务</span>

      <!-- <span class="dash-header-span-datatime"><i :class="weather_error_flag?'el-icon-success':'el-icon-error'" :style="weather_error_flag?'color: #00ffbe;margin-right: 0.3vw':'color: #ff5076;margin-right: 0.3vw'"></i>天气服务</span> -->
    
      <span  class="dash-header-span-datatime"><i class="el-icon-menu" style="color: #02A7F0;margin-right: 0.3vw"></i>进入控制台</span>

    </div>
</template>


<script>

export default{
  props: {
    iot_error_flag: Boolean,
    corporate_name:String,
    timerTick: Boolean,
    title: String,
    name:String,
    theme:String,
    mobileStatus:Boolean
  },
  data() {
    return {
      dataTime: this.getTime(),
      fullscreen: false
    }
  },
  watch: {
    timerTick() {
      this.dataTime = this.getTime(); // 修改数据date
    }
  },
  methods: {
    getTime(){
      let date = new Date()
      let yyyy = date.getFullYear();
      let mm = date.getMonth()+1<10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
      let dd = date.getDate()<10 ? '0'+date.getDate() : date.getDate();
      let hh = date.getHours()<10 ? '0'+date.getHours() : date.getHours();
      let MM = date.getMinutes()<10 ? '0'+date.getMinutes() : date.getMinutes();
      var days = [
        '日',
        '一',
        '二',
        '三',
        '四',
        '五',
        '六'
      ]
      return yyyy+'年'+mm+'月'+dd+'日'+hh+':'+MM+'星期'+days[date.getDay()];
    },
  }
}
</script>


<style lang="scss" scoped>
  .dash-header-dark {
    width:100%;
    height: 6vh;
    margin: 0;
    padding: 1vh;
    background: url('~@/assets/images/dashboard_dark/header_bar_background.png') no-repeat;
    background-size:100% 100%;
    .dash-header-span-title{
      height:100%;float:left;color:#c9e2ff;font-size:1.8vh;margin-top: 1.5vh;
    }
    h1 {
      margin: 0;
      text-align: center;
      color: #02A7F0;
      font-size: 3.5vh;
      line-height: 3.5vh;
      font-family: "Microsoft YaHei";
      font-weight: bolder;
      letter-spacing: 0.3vw;
      word-spacing:0.1vw;
      width: 100%;
      position: absolute;
    }
    .dash-header-span-datatime {
      line-height: 2.2vh;height:100%;float:right;color: #c9e2ff;
      font-size:1.8vh;margin-top: 1.5vh;margin-left: 1.2vw;
    }
  }
  .dash-header {
    width:100%;
    height: 6vh;
    margin: 0;
    padding: 1vh;
    background: url('~@/assets/images/dashboard_dark/header_bar_background.png') no-repeat;
    background-size:100% 100%;
    .dash-header-span-title{
      height:100%;float:left;color:#02A7F0;font-size:1vh;margin-top: 1.5vh;
    }
    h1 {
      margin: 0;
      text-align: center;
      color: #02A7F0;
      font-size: 2.2vh;
      line-height: 3.5vh;
      font-family: "Microsoft YaHei";
      font-weight: bolder;
      letter-spacing: 0.3vw;
      word-spacing:0.1vw;
      width: 100%;
      position: absolute;
    }
    .dash-header-span-datatime {
      line-height: 2.2vh;height:100%;float:right;color: #ffffff;
      font-size:1vh;margin-top: 1.5vh;margin-left: 1.2vw;
    }
  }
</style>