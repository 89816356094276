<template>
    <div :class="'dash-container-dark'">
      <div style="float: right; z-index: 0">
        <div class="main-counter">
          <transition name = "fade">
            <div class="container">
              <el-amap class="amap-box"
                       :amap-manager="amapManager"
                       :vid="'amap-vue'"
                       :zoom="zoom"
                       :plugin="plugin"
                       :center="center"
                       :events="events" ></el-amap>
            </div>
          </transition>
        </div>
      </div>
      <div>
        <dash-header :iot_error_flag="iotErrorFlag" :corporate_name="corporateName" style="z-index:10;position: fixed;margin-top: 1vh" :timerTick="timerTick" :mobileStatus="mobileStatus" /><!-- 7vh -->
        <dash-counter  style="z-index:10;position: fixed;margin-top: 12vh" :counter="counter" :mobileStatus="mobileStatus"/><!-- 11vh -->
      </div>
      <div class="dash-body" style="z-index:10;position: fixed;margin-top: 22vh;"><!-- 82vh -->
        <transition name = "fade">
          <dash-body-left v-show = "!updatingLibrary"
                          :web_socket_data="webSocketData" :mobileStatus="mobileStatus"
          />
        </transition>
      </div>
      <div class="dash-body" style="z-index:10;position: fixed;right:0px;margin-top: 22vh"><!-- 82vh -->
        <dash-body-right
            :alertInfoList="alertInfoList" :highlightAlert="highlightAlert" :highlightAlertIndex="highlightAlertIndex" :mobileStatus="mobileStatus"
        />
      </div>
      <div v-if="mobileStatus" style="z-index: 15;position: fixed;top: 94vh;height: 3vh;right: 19vh;color: white;font-weight: 900; text-align:center;line-height:3vh;">
        <div @click="realTimePolice(0)" :style="(alertFalg==0?'float: left;margin-right: 1vh;width: 6vh;height: 3vh;background: rgb(22, 73, 128)':'float: left;margin-right: 1vh;width: 6vh;height: 3vh;background: rgb(10, 31, 59);')">实时警情</div>
        <div @click="realTimePolice(1)" :style="(alertFalg==1?'float: left;margin-right: 1vh;width: 6vh;height: 3vh;background: rgb(22, 73, 128)':'float: left;margin-right: 1vh;width: 6vh;height: 3vh;background: rgb(10, 31, 59);')">历史警情</div>
        <div @click="realTimePolice(2)" :style="(alertFalg==2?'float: left;width: 6vh;height: 3vh;background: rgb(22, 73, 128)':'float: left;width: 6vh;height: 3vh;background: rgb(10, 31, 59);')">全部设备</div>
      </div>
      <div v-else style="z-index: 15;position: fixed;top: 94vh;height: 4vh;width:42vh;right: 55vh;color: white;font-weight: 900; text-align:center;line-height:4vh;">
        <div @click="realTimePolice(0)" :style="(alertFalg==0?'float: left;margin-right: 2vh;width: 12vh;height: 4vh;background: rgb(22, 73, 128)':'float: left;margin-right: 2vh;width: 12vh;height: 4vh;background: rgb(10, 31, 59);')">实时警情</div>
        <div @click="realTimePolice(1)" :style="(alertFalg==1?'float: left;margin-right: 2vh;width: 12vh;height: 4vh;background: rgb(22, 73, 128)':'float: left;margin-right: 2vh;width: 12vh;height: 4vh;background: rgb(10, 31, 59);')">历史警情</div>
        <div @click="realTimePolice(2)" :style="(alertFalg==2?'float: left;margin-right: 2vh;width: 12vh;height: 4vh;background: rgb(22, 73, 128)':'float: left;margin-right: 2vh;width: 12vh;height: 4vh;background: rgb(10, 31, 59);')">全部设备</div>
      </div>
    </div>


</template>

<script>
// import location from "@/assets/images/logo.png"
import {getOneById} from '@/api/equipment'
import dashHeader from './dashHeader'
import dashCounter from './dashCounter'
import dashBodyLeft from './dashBodyLeft'
import dashBodyRight from './dashBodyRight'
import {getCorporateName} from '@/api/user'
import {AMapManager, lazyAMapApiLoaderInstance} from 'vue-amap'
import ReconnectingWebSocket from '@/utils/reconnecting-websocket'
import {getAreaById} from '@/api/area'
import { isMobile } from '@/utils/common'
let amapManager = new AMapManager()

export default{
  components: {
    dashHeader,
    dashCounter,
    dashBodyLeft,
    dashBodyRight
  },
  data() {
    let self = this
    return {
      status:["离线","在线","报警","3故障"],
      iotErrorFlag: null,
      highlightAlertTimer:null,
      highlightAlertIndex:null,
      highlightAlert:[],
      ambientHighTemperature:require('@/assets/images/ambient_high_temperature.png'),
      mapHighRegion:[],
      alertFalg:0,
      alertText:[
          null,
        require('@/icons/svg/fire.svg'),
        require('@/icons/svg/water.svg'),
        require('@/icons/svg/temperature.svg'),
        require('@/icons/svg/humidity.svg'),
        require('@/icons/svg/damage.svg'),
        require('@/icons/svg/offline.svg'),
      ],
      map:null,
      webSocketData:null,
      address: null,
      searchKey: '',
      amapManager,
      markers: [],
      searchOption: {
        city: '全国',
        citylimit: true
      },
      center: [107.00, 35.78],
      // center: [109.329402, 38.228667],
      zoom: 5,
      lng: 0,
      lat: 0,
      loaded: false,
      events: {
        init() {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initSearch()
          })
        },
        // 点击获取地址的数据
        click(e) {
          // console.log(e)
          self.markers = []
          let {lng, lat} = e.lnglat
          self.lng = lng
          self.lat = lat
          self.center = [lng, lat]
          self.markers.push([lng, lat])
        }
      },
      // 一些工具插件
      plugin: [],

      counter:{
        equipmentQuantity:0,
        lowEquipmentQuantity:0,
        releasedNot:0,
        onTimeAuthorization:0,
        faultyEquipmentRecent:0,
        informationPolice:[]
      },
      alertInfoList: [],
      libAlerts:[],
      libFailureList:[],
      highestAlertLeval:null,
      libraryIndex:0, // 当前显示库房，dataList下标
      libIdMatchIndexListAll: [], // dataList 中libId对应关系 libId:index, 完整的
      libIdMatchIndexList: [], // dataList 中libId对应关系 libId:index,有警情时只有警情库房，没有警情时是所有库房
      timerTickDelay: 200,
      timerTick: true, // 定时翻转 用于子组件更新页面
      updatingLibraryCount: 0,
      updatingLibrary: false, // 切换库房时启动动态效果
      socket: "",
      corporateName:'',
      mapWeather:[],
      weatherUrl:[
          null,
        require('@/assets/images/rainstorm10.png'),
        require('@/assets/images/rainstorm20.png'),
        require('@/assets/images/rainstorm30.png'),
      ],
      quipmentImage:[[],
        [
          require('@/assets/images/HM-633PH-NBoff-line.svg'),
          require('@/assets/images/HM-633PH-NBnormal.svg'),
          require('@/assets/images/HM-633PH-NBpolice.svg'),
          require('@/assets/images/HM-633PH-NBpolice.svg')
        ],[
          require('@/assets/images/HS2WL-NBoff-line.svg'),
          require('@/assets/images/HS2WL-NBnormal.svg'),
          require('@/assets/images/HS2WL-NBpolice.svg'),
          require('@/assets/images/HS2WL-NBpolice.svg')
        ],[
          require('@/assets/images/FZ-602off-line.svg'),
          require('@/assets/images/FZ-602normal.svg'),
          require('@/assets/images/FZ-602police.svg'),
          require('@/assets/images/FZ-602police.svg')
        ],
      ],//（0离线，1在线，2报警，3故障）
      mobileStatus:false//手机宽度分辨率是否小于400
    }
  },
  created(){ // 配置数据 create 时候更新，业务数据mounted开始更新
  },
  mounted(){
    var aler= []
    this.setAlertInfoList(aler)
    getCorporateName().then(res => {
      if(res.data && res.data.state=="ok"){
        this.corporateName = res.data.data
        this.initSocket(res.data.company);
      }else{
        this.$message.error(res.data.msg ? res.data.msg :'登录失败')
      }
    })
    if (isMobile() && window.screen.width < 420) {
      this.mobileStatus = true
    }
  },
  beforeDestroy() {
    if (this.highlightAlertTimer) {
      clearInterval(this.highlightAlertTimer); // 在Vue实例销毁前，清除定时器
    }
    this.socket.close()
  },
  methods: {
    //雨云
    anomalyWeather(weather){
      // if(this.mapWeather){
      //   this.map.remove(this.mapWeather);
      // }
      let alert = []
      var zoom = this.map.getZoom();
      let level = 4
      if(zoom<8){
        level = 3
      }
      for (let i = 0; i < weather.length; i++) {
        if(level != weather[i].level){
          continue
        }
        let icon = new AMap.Icon({
          image: this.weatherUrl[weather[i].weather],
          size: new AMap.Size(70, 70),
          imageSize: new AMap.Size(70, 70)
        });
        //初始化覆盖物对象并赋值给vue对象
        let markerMain = new AMap.Marker({
          icon: icon, //使用自定义的图标
          position: [weather[i].longitude,weather[i].latitude] //覆盖物位置坐标
        });
        markerMain.weather = weather[i].weather
        markerMain.id = weather[i].id
        alert.push(markerMain)
      }

      let _this = this;
      for (let i = 0; i < alert.length; i++) {
        let j = 0;
        for(;j<this.mapWeather.length;j++){
          if(this.mapWeather[j].id == alert[i].id){
            if(this.mapWeather[j].weather == alert[i].weather){
              alert[i] = this.mapWeather[j]
              break
            }else {
              this.map.remove(this.mapWeather[j]);
              alert[i].on( 'click', function (e){
                getAreaById({id:alert[i].id}).then(res => {
                  var content = [];
                  content.push("<div style='background-color: white;width: 420px;height: 162px;border: 1px solid #d2d2d2;box-shadow: 3px 3px 12px -1px rgba(255,255,255,.8);padding-left: 9px;padding-right: 9px;border-radius: 5px;'>");
                  content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>区域</div>");
                  content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>天气</div>");
                  content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.region+"</div>");
                  content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.weather+"</div>");
                  content.push("</div>");
                  let contextMenu = new AMap.ContextMenu({isCustom: true, content: content.join('')})
                  contextMenu.open(_this.map, e.lnglat);
                })
              })
              this.map.add(alert[i])
              break
            }
          }
        }
        if(j == this.mapWeather.length){
          alert[i].on( 'click', function (e){
            getAreaById({id:alert[i].id}).then(res => {
              var content = [];
              content.push("<div style='background-color: white;width: 420px;height: 162px;border: 1px solid #d2d2d2;box-shadow: 3px 3px 12px -1px rgba(255,255,255,.8);padding-left: 9px;padding-right: 9px;border-radius: 5px;'>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>区域</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>天气</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.region+"</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.weather+"</div>");
              content.push("</div>");
              let contextMenu = new AMap.ContextMenu({isCustom: true, content: content.join('')})
              contextMenu.open(_this.map, e.lnglat);
            })
          })
          this.map.add(alert[i])
        }
      }
      for (let i = 0; i < this.mapWeather.length; i++) {
        let j = 0
        for (; j < alert.length; j++) {
          if(alert[j].id == this.mapWeather[i].id){
            break
          }
        }
        if(j == alert.length){
          this.map.remove(this.mapWeather[i]);
        }
      }
      this.mapWeather = alert
    },
    anomalyTem(region){
      if(this.mapHighRegion){
        this.map.remove(this.mapHighRegion);
      }
      let alert = []
      for (let i = 0; i < region.length; i++) {
          let icon = new AMap.Icon({
          image: this.ambientHighTemperature,
          size: new AMap.Size(70, 70),
          imageSize: new AMap.Size(70, 70)
        });
        //初始化覆盖物对象并赋值给vue对象
        let markerMain = new AMap.Marker({
          icon: icon, //使用自定义的图标
          position: [region[i].longitude,region[i].latitude] //覆盖物位置坐标
        });
        markerMain.id = region[i].id
        alert.push(markerMain)
      }
      let _this = this
      for (let i = 0; i < alert.length; i++) {
        alert[i].on( 'click', function (e){
          getAreaById({id:alert[i].id}).then(res => {
            var content = [];
            content.push("<div style='background-color: white;width: 420px;height: 162px;border: 1px solid #d2d2d2;box-shadow: 3px 3px 12px -1px rgba(255,255,255,.8);padding-left: 9px;padding-right: 9px;border-radius: 5px;'>");
            content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>区域</div>");
            content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>温度</div>");
            content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.region+"</div>");
            content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.temperature+"</div>");
            content.push("</div>");
            let contextMenu = new AMap.ContextMenu({isCustom: true, content: content.join('')})
            contextMenu.open(_this.map, e.lnglat);
          })
        })
        this.map.add(alert[i])
      }
      this.mapHighRegion = alert
    },
    allEquipment(){
      this.alertFalg = 2
      if(this.mapAlerts){
        this.map.remove(this.mapAlerts);
      }
      let alert = []
        for (let i = 0; i < this.equipments.length; i++) {
          let icon = new AMap.Icon({
            image: this.quipmentImage[this.equipments[i].type][this.equipments[i].status],
            size: new AMap.Size(30, 30),
            imageSize: new AMap.Size(30, 30)
          });
          //初始化覆盖物对象并赋值给vue对象
          let markerMain = new AMap.Marker({
            icon: icon, //使用自定义的图标
            position: [this.equipments[i].longitude,this.equipments[i].latitude] //覆盖物位置坐标
          });
          markerMain.id = this.equipments[i].id
          let _this = this
          alert.push(markerMain)
          for (let i = 0; i < alert.length; i++) {
            alert[i].on( 'click', function (e){
            getOneById({id:alert[i].id}).then(res => {
              var content = [];                  
              content.push("<div style='background-color: white;width: 620px;height: 162px;border: 1px solid #d2d2d2;box-shadow: 3px 3px 12px -1px rgba(255,255,255,.8);padding-left: 9px;padding-right: 9px;border-radius: 5px;'>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>库房</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>设备名称</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;border-bottom: 1px solid #d2d2d2;'>状态</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.library+"</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+res.data.data.name+"</div>");
              content.push("  <div style='float: left;width: 200px;height: 80px;text-align: center;line-height: 80px;'>"+_this.status[res.data.data.status]+"</div>");
              content.push("</div>");
              let contextMenu = new AMap.ContextMenu({isCustom: true, content: content.join('')})
              contextMenu.open(_this.map, e.lnglat);
            })
          })
          this.map.add(alert[i])
        }
        this.mapAlerts = alert
      }
    },
    realTimePolice(falg){
      if (falg == 2){
        this.allEquipment()
        return
      }
      // 生成自定义的图标
      this.alertFalg = falg
      if(this.mapAlerts){
        this.map.remove(this.mapAlerts);
      }
      let alert = []
      for (let i = 0; i < this.alertInfoList.length; i++) {
        if (this.alertInfoList[i].relieve == falg) {
          let icon = new AMap.Icon({
            image: this.alertText[this.alertInfoList[i].type],
            size: new AMap.Size(30, 30),
            imageSize: new AMap.Size(30, 30)
          });
          //初始化覆盖物对象并赋值给vue对象
          let markerMain = new AMap.Marker({
            icon: icon, //使用自定义的图标
            position: [this.alertInfoList[i].longitude,this.alertInfoList[i].latitude] //覆盖物位置坐标
          });
          markerMain.longitude = this.alertInfoList[i].longitude
          markerMain.latitude = this.alertInfoList[i].latitude
          markerMain.id = this.alertInfoList[i].id
          alert.push(markerMain)
        }
      }
      let _this = this
      for (let i = 0; i < alert.length; i++) {
        if (falg == 0) {
          alert[i].on('click', function (e) {
            clearInterval(_this.highlightAlertTimer)
            _this.highlightAlertIndex = 1
            _this.highlightAlert = []
            for (let j = 0; j < alert.length; j++) {
              if(alert[j].longitude == alert[i].longitude && alert[j].latitude == alert[i].latitude){
                _this.highlightAlert.push(alert[j].id);
              }
            }
            _this.highlightAlertTimer = setInterval(() => {
              if (_this.highlightAlertIndex == 50) {
                _this.highlightAlert = null
                _this.highlightAlertIndex = 1
                clearInterval(_this.highlightAlertTimer)
              }
              _this.highlightAlertIndex++
            }, 60)
          })
        }
        this.map.add(alert[i])
      }
      this.mapAlerts = alert
    },
    setAlertInfoList(aler){
      var date =new Date()
      for(let i = 0;i < aler.length; i++){
        let dateTime = new Date(aler[i].time)
        let day = parseInt((date - dateTime)/1000/60/60/24)
        if(day == 0){
          let hour = parseInt((date - dateTime)/1000/60/60)
          if(hour == 0){
            aler[i].time = parseInt((date - dateTime)/1000/60) +'分钟前'
          }else {
            aler[i].time = hour+'小时前'
          }
        }else if(day == 1){
          aler[i].time = '一天前'
        }else if(day == 2){
          aler[i].time = '两天前'
        }else if(day == 3){
          aler[i].time = '三天前'
        }
      }
      this.alertInfoList = aler
    },
    initSearch() {
      let map = this.amapManager.getMap()
      map.setMapStyle('amap://styles/dark');
      this.map = map
      let _this = this
      this.map.on('zoomend',function (){
        _this.anomalyWeather(_this.webSocketData.anomalyWeather)
      });
    },
    initSocket(company) {
      if(typeof(WebSocket) === "undefined"){
        console.log("您的浏览器不支持socket")
      }else{
        // 实例化socket
        let url = (location.protocol === 'https:' ? 'wss' : 'ws') + "://"+location.host+"/socket/exhibition.ws/dashboard/1"
        this.socket = new ReconnectingWebSocket(url)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
        //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = () => {
          if (this.socket && this.socket.readyState != 2 && this.socket.readyState != 3) {
            this.socket.close()
          }
        }
      }
    },
    open() {
      console.log("socket connected")
      // this.updateLibraryData()
      // // w  ebsocket 开启后启动定时器，并开始更新数据
      // if(typeof this.timer == "undefined")
      //   this.initTimer()
    },
    error() {
      console.log("connect error")
    },
    getMessage(msg) {
      let data = JSON.parse(msg.data)
      this.webSocketData = data
      this.counter.equipmentQuantity = this.webSocketData.equipmentQuantity
      this.counter.lowEquipmentQuantity = this.webSocketData.lowEquipmentQuantity
      this.counter.releasedNot = this.webSocketData.releasedNot
      this.counter.onTimeAuthorization = this.webSocketData.onTimeAuthorization
      this.counter.faultyEquipmentRecent = this.webSocketData.faultyEquipmentRecent
      this.iotErrorFlag = this.webSocketData.iotErrorFlag
      this.equipments = this.webSocketData.equipments
      this.anomalyTem(this.webSocketData.anomalyTem)
      this.anomalyWeather(this.webSocketData.anomalyWeather)
      this.setAlertInfoList(this.webSocketData.informationPolice)
      this.realTimePolice(this.alertFalg);
    },
    socketSend(params) {
      this.socket.send(params)
    },

  },
  destroyed () {
  }
}
</script>

<style scoped>
* {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.fade-enter-active.dash-left {
  transition: margin-left 0.2s;
}
.fade-enter.dash-left{
  margin-left:45vw;
}
/* 
.fade-enter-active.dash-right {
  transition: margin-right 0.1s;
}
.fade-enter.dash-right{
  margin-right:-60vw;
} */

.fade-enter-active.library-3d {
  transition: opacity 0.2s;
}
.fade-enter.library-3d{
  opacity:0;
}


</style>

<style lang="scss" scoped>
.amap-logo {
  display: none!important;
  visibility: hidden!important;
}

.amap-copyright {
  display: none !important;
  visibility: hidden !important;
}

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
}

.search-box {
  position: absolute;
  z-index: 5;
  width: 70%;
  left: 13%;
  top: 10px;
  height: 30px;
}

.search-box input {
  float: left;
  width: 80%;
  height: 100%;
  border: 1px solid #30ccc1;
  padding: 0 8px;
  outline: none;
}

.search-box button {
  float: left;
  width: 20%;
  height: 100%;
  background: #30ccc1;
  border: 1px solid #30ccc1;
  color: #fff;
  outline: none;
}

.tip-box {
  width: 100%;
  max-height: 260px;
  position: absolute;
  top: 30px;
  overflow-y: auto;
  background-color: #fff;
}

html,body,#app{
  height: 100%;
  margin: 0 0;
  overflow: hidden;
}

.dash-container-dark {
  height:100vh;
  width:100vw;
  //background: url('~@/assets/images/dashboard_dark/background.png') no-repeat;
  // background: url('~@/assets/temp/dark.png') no-repeat;
  background-size:100% 100%;
  overflow: hidden;

  // 主区域
  .dash-body {
    float: left;
    z-index: 0;
    height: 78vh;
    margin: 1vh 1vw;
    padding: 0;
    
    .dash-main {
      position: fixed;
      width: 98vw;
      height: 100%;
      float: left;
      .dash-main-header {
        height: 3vh;
        margin-top: 2vh;
        margin-left: 42vh;
        margin-right: 54vh;
        font-size: 2vh;
        color: #fff;
        .main-header-box {
          margin: 0 36% 0 3%;
          border-bottom: 1px solid #38588F;
        }
      }
      .main-counter {
        height: 75vh;
        color: #FEFFFF;
        .library-3d {
          position: absolute;
          height: 75vh;
          line-height: 75vh;
          width: calc(100vw - 34vh - 47vh);
          text-align: center;
          margin: 0vh 47vh 0vh 34vh;
          a {
            display: block;
            height: 75vh;
            line-height: 75vh;
            .img-3dshow {
              max-width: calc(100vw - 47vh - 34vh);
              max-height: 68vh;
              position: relative;
              vertical-align: middle;
              z-index: -1;
            }
          }
        }
        .eq-title, .eq-tag, .eq-info{
          width:100%;
          padding-left: 51vh;
          padding-right: 56vh;
        }
        .eq-title {
          bottom: 14vh;
          float: right;
          text-align: right;
          margin-top: 55vh;
          span {
            font-size: 1.6vh;
            padding:4px 2vh;
            background: rgba(180, 29, 34, 0.5);
          }
        }
        .eq-tag {
          bottom: 8vh;
          margin-top: 1.5vh;
          font-size: 2vh;
          float: right;
          text-align: right;
        }
        .eq-info {
          height: 5vh;
          margin-top: 1.5vh;
          float: right;
          text-align: right;
          .eq-info-box {
            height: 100%;
            float: right;
            margin-left:1.5vw;
            .eq-info-cell {
              height: 5vh;
              padding-left: 1vh;
              float: right;
              svg {
                width: 5vh;
                height: 5vh;
                border: 1px solid #12C1FA;
                padding:1vh;
                border-radius: 3vh;
              }
              p {
                margin-top: 1vh;
                margin-bottom: 0;
                text-align: left;
              }
              p:first-child {
                font-size: 2vh;
                line-height: 2vh;
              }
              p:last-child {
                font-size: 1vh;
                line-height: 1vh;
              }
              span {
                font-size: 4vh;
                color: #12C1FA;
                span {
                  font-size: 2vh;
                }
              }
            }
            .eq-info-cell:first-child {
              display: flex;
              align-items: flex-end;
            }
          }
        }
      }
    }
  }
  .alert-dialog {
    height: 26vh;
    min-height: 200px;
    width: 45vh;
    min-width: 350px;
    margin: 0 auto;
    margin-top: 40vh;
    background: url('~@/assets/images/dashboard_dark/alert-dialog.png') no-repeat center;
    background-size: contain;
    box-shadow: unset;
    transform: none;
    left: 0;
    position: relative;
    span,p {
      color: #FFFFFF;
    }
    .el-dialog__header {
      font-weight: bolder;
      height: 6vh;
      min-height: 50px;
      padding: 2vh;
    }
    .el-dialog__title {
      font-size: 2vh;
      line-height: calc(2vh + 4px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }
    .el-dialog__subtitle {
      font-size: 1.5vh;
    }
    .el-dialog__body {
      height: 20vh;
      min-height: 150px;
      padding: 5% 5% 12% 8%;
      .alert-lantern {
        width: 35%;
        height: 100%;
        float: left;
        // border: 1px solid #aaa;
      }
      .alert-info {
        width: 28%;
        height: 100%;
        margin-left: 3%;
        margin-top: 5%;
        float: left;
        // border: 1px solid #aaa;
        .info-title {
          font-size: 2vh;
          line-height: 2vh;
          margin-bottom: 0;
        }
        .info-countent {
          font-size: 4vh;
          line-height: 4vh;
          margin-top: 1vh;
          color: #12C1FA;
          span {
            font-size: 2vh;
            color: #12C1FA;
          }
        }
        .warning, .warning span{
          color: #FD2C33
        }
      }
    }
  }
}
</style>
