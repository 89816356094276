<template>
    <div :class=" mobileStatus? 'mobile-dash-right':'dash-right-dark' ">
      <div class="dash-right-container">
        <el-table
          :data="alertInfoList"
          :row-class-name="tableRowClassName"
          :show-header="false"
        >
          <el-table-column
              prop="type_name"
              align="center"
              label="警情">
          </el-table-column>
          <el-table-column
            prop="name"
            label="库房"
            align="center"
            >
          </el-table-column>
          <el-table-column
              prop="time"
              label="时间"
              align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      
    </div>
</template>
<script>
export default {
  props:{
    highlightAlertIndex: Number,
    highlightAlert: Array,
    alertInfoList: Array,
    theme:String,
    mobileStatus:Boolean
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      for (let i = 0; i < this.highlightAlert.length; i++) {
        if(row.id == this.highlightAlert[i]){
          let sty = 'highlight-alert-row'+ this.highlightAlertIndex
          console.log(sty)
          return sty;
        }
      }

      if (row.relieve == 0) {
        return 'warning-row';
      }
      return '';
    }
  }
}
</script>

<style lang="scss" scoped>

  .dash-right-dark {
    height: 100%;
    width: 50vh;
    // min-width: 20vw;
    float: right;

    .dash-right-container {
      height: 78vh;
      width: 100%;
      margin-top: 1vh;
      padding: 1vh 0;
      background: rgba(29, 44, 59, 0.6);
      border: 1px solid rgba(47,67,97,0.6);
      .right-layout-box{
        position: sticky;
        bottom: 0;
        // width: 100%;
        height: 18vh;
        margin:1vh;
        padding: 1vh;
        border-top: 1px solid #2F4361;
        img {
          height: 16vh;
        }
      }
    }
  }
  .mobile-dash-right {
    height: 100%;
    width: 20vh;
    // min-width: 20vw;
    float: right;
    .dash-right-container {
      height: 78vh;
      width: 100%;
      margin-top: 1vh;
      padding: 1vh 0;
      background: rgba(29, 44, 59, 0.6);
      border: 1px solid rgba(47,67,97,0.6);
      .right-layout-box{
        position: sticky;
        bottom: 0;
        // width: 100%;
        height: 18vh;
        margin:1vh;
        padding: 1vh;
        border-top: 1px solid #2F4361;
        img {
          height: 16vh;
        }
      }
    }
  }
</style>

<style scoped>
    .dash-right-dark>>>.el-table {
      height: 100%;
      width: 100%;
      font-size:1vh;
      color:#B5E1EA;
      background:unset;
      overflow: hidden;
    }
    .dash-right-dark>>>.el-table::before,.dash-right-dark>>>.el-table::before, .dash-right-dark>>>.el-table--group::after,.dash-right-dark>>>.el-table--border::after{
      height: 0;
    }
    .dash-right-dark>>>.el-table td {
      padding: 0.5vh 1vh;
    }
    .dash-right-dark>>>.el-table .cell {
      padding:2px;
      font-size: 1.1vh;
      line-height: 2vh;
    }
    .dash-right-dark>>>.el-table tr{
      background:unset;
    }
    .dash-right-dark>>>.el-table tr th {
      background:unset;
      padding:0 1vh;
      color:#B5E1EA;
    }
    .dash-right-dark .el-table>>>th.is-leaf,.dash-right-dark .el-table>>>td {
      border-bottom: unset;
      border-bottom: 1px solid rgba(10, 31, 59, 0.6);
    }
    .dash-right-dark .el-table>>>tr:hover td {
      background: unset;
    }
    .dash-right-dark>>>.el-table tr.warning-row td {
      background: rgba(180,29,34,0.5);
      font-weight: bold;
    }

    .mobile-dash-right>>>.el-table {
      height: 100%;
      width: 100%;
      font-size:1vh;
      color:#B5E1EA;
      background:unset;
      overflow: hidden;
    }
    .mobile-dash-right>>>.el-table::before,.mobile-dash-right>>>.el-table::before, .mobile-dash-right>>>.el-table--group::after, .mobile-dash-right>>>.el-table--border::after{
      height: 0;
    }
    .mobile-dash-right>>>.el-table td {
      padding: 0.5vh 1vh;
    }
    .mobile-dash-right>>>.el-table .cell {
      padding:2px;
      font-size: 1vh;
      line-height: 1.1vh;
    }
    .mobile-dash-right>>>.el-table tr{
      background:unset;
    }
    .mobile-dash-right>>>.el-table tr th {
      background:unset;
      padding:0 1vh;
      color:#B5E1EA;
    }
    .mobile-dash-right .el-table>>>th.is-leaf,.mobile-dash-right .el-table>>>td {
      border-bottom: unset;
      border-bottom: 1px solid rgba(10, 31, 59, 0.6);
    }
    .mobile-dash-right .el-table>>>tr:hover td {
      background: unset;
    }
    .mobile-dash-right>>>.el-table tr.warning-row td {
      background: rgba(180,29,34,0.5);
      font-weight: bold;
    }
  .dash-right-dark>>>.el-table tr.highlight-alert-row1 td {
    background: rgba(180,29,34,0.99);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row2 td {
    background: rgba(180,29,34,0.98);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row3 td {
    background: rgba(180,29,34,0.97);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row4 td {
    background: rgba(180,29,34,0.96);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row5 td {
    background: rgba(180,29,34,0.95);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row6 td {
    background: rgba(180,29,34,0.94);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row7 td {
    background: rgba(180,29,34,0.93);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row8 td {
    background: rgba(180,29,34,0.92);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row9 td {
    background: rgba(180,29,34,0.91);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row10 td {
    background: rgba(180,29,34,0.9);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row11 td {
    background: rgba(180,29,34,0.89);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row12 td {
    background: rgba(180,29,34,0.88);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row13 td {
    background: rgba(180,29,34,0.87);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row14 td {
    background: rgba(180,29,34,0.86);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row15 td {
    background: rgba(180,29,34,0.85);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row16 td {
    background: rgba(180,29,34,0.84);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row17 td {
    background: rgba(180,29,34,0.83);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row18 td {
    background: rgba(180,29,34,0.82);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row19 td {
    background: rgba(180,29,34,0.81);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row20 td {
    background: rgba(180,29,34,0.8);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row21 td {
    background: rgba(180,29,34,0.79);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row22 td {
    background: rgba(180,29,34,0.78);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row23 td {
    background: rgba(180,29,34,0.77);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row24 td {
    background: rgba(180,29,34,0.76);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row25 td {
    background: rgba(180,29,34,0.75);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row26 td {
    background: rgba(180,29,34,0.74);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row27 td {
    background: rgba(180,29,34,0.73);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row28 td {
    background: rgba(180,29,34,0.72);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row29 td {
    background: rgba(180,29,34,0.71);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row30 td {
    background: rgba(180,29,34,0.7);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row31 td {
    background: rgba(180,29,34,0.69);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row32 td {
    background: rgba(180,29,34,0.68);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row33 td {
    background: rgba(180,29,34,0.67);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row34 td {
    background: rgba(180,29,34,0.66);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row35 td {
    background: rgba(180,29,34,0.65);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row36 td {
    background: rgba(180,29,34,0.64);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row37 td {
    background: rgba(180,29,34,0.63);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row38 td {
    background: rgba(180,29,34,0.62);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row39 td {
    background: rgba(180,29,34,0.61);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row40 td {
    background: rgba(180,29,34,0.6);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row41 td {
    background: rgba(180,29,34,0.59);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row42 td {
    background: rgba(180,29,34,0.58);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row43 td {
    background: rgba(180,29,34,0.57);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row44 td {
    background: rgba(180,29,34,0.56);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row45 td {
    background: rgba(180,29,34,0.55);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row46 td {
    background: rgba(180,29,34,0.54);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row47 td {
    background: rgba(180,29,34,0.53);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row48 td {
    background: rgba(180,29,34,0.52);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row49 td {
    background: rgba(180,29,34,0.51);
    font-weight: bold;
  }
  .dash-right-dark>>>.el-table tr.highlight-alert-row50 td {
    background: rgba(180,29,34,0.5);
    font-weight: bold;
  }

  .mobile-dash-right>>>.el-table tr.highlight-alert-row1 td {
    background: rgba(180,29,34,0.99);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row2 td {
    background: rgba(180,29,34,0.98);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row3 td {
    background: rgba(180,29,34,0.97);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row4 td {
    background: rgba(180,29,34,0.96);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row5 td {
    background: rgba(180,29,34,0.95);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row6 td {
    background: rgba(180,29,34,0.94);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row7 td {
    background: rgba(180,29,34,0.93);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row8 td {
    background: rgba(180,29,34,0.92);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row9 td {
    background: rgba(180,29,34,0.91);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row10 td {
    background: rgba(180,29,34,0.9);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row11 td {
    background: rgba(180,29,34,0.89);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row12 td {
    background: rgba(180,29,34,0.88);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row13 td {
    background: rgba(180,29,34,0.87);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row14 td {
    background: rgba(180,29,34,0.86);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row15 td {
    background: rgba(180,29,34,0.85);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row16 td {
    background: rgba(180,29,34,0.84);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row17 td {
    background: rgba(180,29,34,0.83);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row18 td {
    background: rgba(180,29,34,0.82);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row19 td {
    background: rgba(180,29,34,0.81);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row20 td {
    background: rgba(180,29,34,0.8);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row21 td {
    background: rgba(180,29,34,0.79);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row22 td {
    background: rgba(180,29,34,0.78);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row23 td {
    background: rgba(180,29,34,0.77);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row24 td {
    background: rgba(180,29,34,0.76);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row25 td {
    background: rgba(180,29,34,0.75);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row26 td {
    background: rgba(180,29,34,0.74);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row27 td {
    background: rgba(180,29,34,0.73);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row28 td {
    background: rgba(180,29,34,0.72);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row29 td {
    background: rgba(180,29,34,0.71);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row30 td {
    background: rgba(180,29,34,0.7);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row31 td {
    background: rgba(180,29,34,0.69);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row32 td {
    background: rgba(180,29,34,0.68);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row33 td {
    background: rgba(180,29,34,0.67);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row34 td {
    background: rgba(180,29,34,0.66);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row35 td {
    background: rgba(180,29,34,0.65);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row36 td {
    background: rgba(180,29,34,0.64);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row37 td {
    background: rgba(180,29,34,0.63);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row38 td {
    background: rgba(180,29,34,0.62);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row39 td {
    background: rgba(180,29,34,0.61);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row40 td {
    background: rgba(180,29,34,0.6);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row41 td {
    background: rgba(180,29,34,0.59);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row42 td {
    background: rgba(180,29,34,0.58);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row43 td {
    background: rgba(180,29,34,0.57);
    font-weight: bold;
  }
  .mobile-dash-rightk>>>.el-table tr.highlight-alert-row44 td {
    background: rgba(180,29,34,0.56);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row45 td {
    background: rgba(180,29,34,0.55);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row46 td {
    background: rgba(180,29,34,0.54);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row47 td {
    background: rgba(180,29,34,0.53);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row48 td {
    background: rgba(180,29,34,0.52);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row49 td {
    background: rgba(180,29,34,0.51);
    font-weight: bold;
  }
  .mobile-dash-right>>>.el-table tr.highlight-alert-row50 td {
    background: rgba(180,29,34,0.5);
    font-weight: bold;
  }
</style>