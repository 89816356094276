<template>
  <div style="color:white;width: 98%; margin-left: 2vh;margin-right: 2vh; height: 8vh;">
    <div :class="mobileStatus?'mobileBack1':'back1'">
      <div :class="mobileStatus?'mobile-text-left':'text-left'">接入设备</div>
      <div :class="mobileStatus?'mobile-text-number':'text-number'">{{counter.equipmentQuantity}}</div>
      <div :class="mobileStatus?'mobile-text-right':'text-right'">个</div>
    </div>
    <div :class="mobileStatus?'mobileBack2':'back2'">
      <div :class="mobileStatus?'mobile-text-left':'text-left'">低电设备</div>
      <div :class="mobileStatus?'mobile-text-number':'text-number'">{{counter.lowEquipmentQuantity}}</div>
      <div :class="mobileStatus?'mobile-text-right':'text-right'">个</div>
    </div>
    <div :class="mobileStatus?'mobileBack3':'back3'">
      <div :class="mobileStatus?'mobile-text-left':'text-left'">未解除警情</div>
      <div :class="mobileStatus?'mobile-text-number':'text-number'">{{counter.releasedNot}}</div>
      <div :class="mobileStatus?'mobile-text-right':'text-right'">个</div>
    </div>
    <div :class="mobileStatus?'mobileBack4':'back4'">
      <div :class="mobileStatus?'mobile-text-left':'text-left'">故障设备</div>
      <div :class="mobileStatus?'mobile-text-number':'text-number'">{{counter.faultyEquipmentRecent}}</div>
      <div :class="mobileStatus?'mobile-text-right':'text-right'">个</div>
    </div>
    <div class="back5">
      <div :class="mobileStatus?'mobile-text-left':'text-left'">临期授权</div>
      <div :class="mobileStatus?'mobile-text-number':'text-number'">{{counter.onTimeAuthorization}}</div>
      <div :class="mobileStatus?'mobile-text-right':'text-right'">个</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    counter: Object,
    theme:String,
    mobileStatus:Boolean
  }
}
</script>

<style lang="scss" scoped>
.back1{
  float: left; width: 14vw; margin-right: 7vw; height: 100%;background-color: rgba(54,169,206,0.5);
}
.back2{
  float: left; width: 14vw; margin-right: 7vw; height: 100%;background-color: rgba(245,154,35,0.5);
}
.back3{
  float: left; width: 14vw; margin-right: 7vw; height: 100%;background-color: rgba(254,223,84,0.5);
}
.back4{
  float: left; width: 14vw; margin-right: 7vw; height: 100%;background-color: rgba(239,90,161,0.5);
}
.back5{
  float: left; width: 14vw; height: 100%;background-color: rgba(77, 77, 77,0.5);
}
.text-number{
  margin-top:2vh;height: 50%;float: left; font-size: 4vh;min-width: 8vw;text-align: right
}
.text-right{
  margin-top:4vh;height: 50%;float: left; font-size: 1vw;
}
.text-left{
  margin-top:4vh;height: 50%;float: left; padding-left: 0.5vw; font-size: 1.5vh;width: 4.5vw;
}
.mobileBack1{
  float: left; width: 15vw; margin-right: 5vw; height: 100%;background-color: rgba(54,169,206,0.5);
}
.mobileBack2{
  float: left; width: 15vw; margin-right: 5vw; height: 100%;background-color: rgba(245,154,35,0.5);
}
.mobileBack3{
  float: left; width: 15vw; margin-right: 5vw; height: 100%;background-color: rgba(254,223,84,0.5);
}
.mobileBack4{
  float: left; width: 15vw; margin-right: 5vw; height: 100%;background-color: rgba(239,90,161,0.5);
}
.mobile-text-number{
  margin-top:2vh;height: 50%;float: left; font-size: 3vh;min-width: 7vw;text-align: right
}
.mobile-text-right{
  margin-top:4vh;height: 50%;float: left; font-size: 1vw;
}
.mobile-text-left{
  margin-top:4vh;height: 50%;float: left; padding-left: 0.5vw; font-size: 1vh;width: 5.5vw;
}
</style>